import { IChannelContract } from 'window-messages';
import {
  OpenEditor,
  OpenForm,
  Save,
  EntitySearch,
  EntitySearchResults,
  FieldUpdate,
  OptionsUpdate,
  SettingsRequest,
  SettingsUpdate,
  FieldVisibilityUpdate,
  FieldValueUpdate,
  SaveSuccess,
  SaveFail,
  ContentChange,
  LockSave,
  InactiveStatusChange
} from '../actions';

export const contract: IChannelContract = {
  accepted: [
    OpenEditor,
    OpenForm,
    Save,
    SaveSuccess,
    SaveFail,
    FieldUpdate,
    OptionsUpdate,
    EntitySearch,
    EntitySearchResults,
    SettingsUpdate,
    FieldVisibilityUpdate,
    InactiveStatusChange
  ],
  emitted: [OpenForm, Save, LockSave, FieldUpdate, EntitySearch, SettingsRequest, FieldValueUpdate, ContentChange]
};

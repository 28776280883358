import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IAsyncProcessStatus } from '../../../../../src/app/models/async-process';

export interface IHtmlVersionState {
  data: {
    exists: boolean;
    withUnsavedChanges: boolean;
    empty: boolean;
    popupActive: boolean;
    dialogOpen: boolean;
  };
  process: {
    fetchHtml: IAsyncProcessStatus;
    fetchThumbnail: IAsyncProcessStatus;
    loadHtmlEditor: IAsyncProcessStatus;
    saveHtmlChanges: IAsyncProcessStatus;
    fullscreen: IAsyncProcessStatus;
  };
}

export const initialState: IHtmlVersionState = {
  data: {
    exists: null,
    withUnsavedChanges: false,
    empty: null,
    popupActive: false,
    dialogOpen: false
  },
  process: {
    fetchHtml: {
      inProgress: false,
      success: false,
      fail: false
    },
    fetchThumbnail: {
      inProgress: false,
      success: false,
      fail: false
    },
    loadHtmlEditor: {
      inProgress: false,
      success: false,
      fail: false
    },
    saveHtmlChanges: {
      inProgress: false,
      success: false,
      fail: false
    },
    fullscreen: {
      inProgress: false,
      success: false,
      fail: false
    }
  }
};

export interface ModalReference {
  switchEditorModal: NgbModalRef | null;
}

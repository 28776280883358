import { EditorUrlTemplateMap, EditorValue } from '../../../../../shared-contracts/editors.model';
import { IFormState, initialState as formInitialState } from './stepGeneralInformation/step-general-information.model';
import { IHtmlVersionState, initialState as htmlVersionInitialState } from './stepEmailCreative/step-email-creative.model';
import { ITextVersionState, initialState as textVersionInitialState } from './stepTextVersion/step-text-version.model';

import { CRMSettings } from '../../../../../shared-contracts/settings.model';
import { IAsyncProcessStatus } from '../../../../src/app/models/async-process';

export enum SaveOrigin {
  WebAppFooter,
  CRMRibbon
}

export interface IEmailTemplateState {
  form: IFormState;
  htmlVersion: IHtmlVersionState;
  textVersion: ITextVersionState;
  data: {
    activePage: number;
    editorBaseUrl: EditorUrlTemplateMap;
    highlightIncompleteFields: boolean;
    inactive: boolean;
    selectedHtmlEditorUrl: string;
    selectedTextEditorUrl: string;
    settings: CRMSettings;
  };
  process: {
    save: IAsyncProcessStatus;
  };
}

export const EditorNamesMap = {
  [EditorValue.BlockEditor]: 'BLOCK',
  [EditorValue.FreeStyle]: 'FREE_STYLE',
  [EditorValue.Html]: 'HTML',
  [EditorValue.LegacyDragAndDrop]: 'LEGACY_DRAG_AND_DROP',
  [EditorValue.DragAndDrop]: 'DRAG_AND_DROP',
  [EditorValue.EmailDesigner]: 'EMAIL_DESIGNER'
};

export const initialState: IEmailTemplateState = {
  form: formInitialState,
  htmlVersion: htmlVersionInitialState,
  textVersion: textVersionInitialState,
  data: {
    activePage: 1,
    editorBaseUrl: {
      [EditorValue.BlockEditor]: '',
      [EditorValue.FreeStyle]: '',
      [EditorValue.Html]: '',
      [EditorValue.LegacyDragAndDrop]: '',
      [EditorValue.DragAndDrop]: '',
      [EditorValue.EmailDesigner]: '',
      textVersion: '',
      preview: ''
    },
    highlightIncompleteFields: false,
    inactive: false,
    selectedHtmlEditorUrl: null,
    selectedTextEditorUrl: null,
    settings: {
      accountKey: null,
      appId: null,
      crmSpecificVersion: null,
      crmVersion: null,
      entityId: null,
      entityName: null,
      fixedRegion: null,
      orgLcid: null,
      orgname: null,
      region: null,
      sessionId: null,
      subscriptionManagementStatus: null,
      userId: null,
      userLcid: null
    }
  },
  process: {
    save: {
      inProgress: false,
      success: false,
      fail: false
    }
  }
};

import { actionSchema } from 'window-messages';
import { action } from 'ts-action';
import { IVisibility } from '../../shared-contracts/form.model';
import { FieldName } from '../../shared-contracts/dynamics.model';

const type = 'Field Visibility Update';

export const FieldVisibilityUpdate = actionSchema(type, {
  properties: {
    [FieldName.CampaignId]: { type: 'boolean' },
    [FieldName.Category]: { type: 'boolean' },
    [FieldName.CreatedBy]: { type: 'boolean' },
    [FieldName.CreatedOn]: { type: 'boolean' },
    [FieldName.EditorType]: { type: 'boolean' },
    [FieldName.EmailCNameId]: { type: 'boolean' },
    [FieldName.EmailTemplateId]: { type: 'boolean' },
    [FieldName.FromEmail]: { type: 'boolean' },
    [FieldName.FromName]: { type: 'boolean' },
    [FieldName.FromRecordOwner]: { type: 'boolean' },
    [FieldName.FromUserId]: { type: 'boolean' },
    [FieldName.Name]: { type: 'boolean' },
    [FieldName.OwnerId]: { type: 'boolean' },
    [FieldName.PreHeader]: { type: 'boolean' },
    [FieldName.QuickSendTemplate]: { type: 'boolean' },
    [FieldName.Subject]: { type: 'boolean' },
    [FieldName.SubscriptionListId]: { type: 'boolean' },
    [FieldName.Tags]: { type: 'boolean' },
    [FieldName.Transactional]: { type: 'boolean' }
  },
  additionalProperties: true
});

export interface FieldVisibilityUpdateAction extends IVisibility {}

export const fieldVisibilityUpdate = action(type, (fieldVisibility: IVisibility): FieldVisibilityUpdateAction => ({ ...fieldVisibility }));

export enum InboundMessage {
  ContentLoaded = 'content loaded',
  ContentChanged = 'content changed',
  DimensionChanged = 'dimension changed',
  SaveSuccess = 'save successful',
  SaveFail = 'save failed'
}

export enum OutboundMessage {
  ContentStatusRequest = 'content status request',
  Save = 'save'
}

export enum Message {
  ProcessStart = 'process started',
  ProcessSuccess = 'process successful',
  ProcessFail = 'process failed'
}

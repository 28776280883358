import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IAppState } from '../../../../../src/app/app.model';
import { filter, MessageChannel, open, close, invalid, deny } from 'window-messages';
import { FieldName, WebAppEntityName } from '../../../../../../shared-contracts/dynamics.model';
import { TFieldValue } from '../../../../../../shared-contracts/form.model';
import { IEntitySearch } from '../../../../../../shared-contracts/lookups.model';
import {
  EntitySearchResultsAction,
  SettingsUpdateAction,
  FieldUpdateAction,
  OptionsUpdateAction,
  FieldVisibilityUpdateAction,
  SaveSuccessAction,
  SaveFailAction,
  SaveAction,
  InactiveStatusChangeAction,
  OpenEditorAction
} from '../../../../../../window-message-exchange/actions';
import {
  openEditor,
  openForm,
  settingsRequest,
  save,
  saveSuccess,
  saveFail,
  lockSave,
  entitySearch,
  entitySearchResults,
  fieldVisibilityUpdate,
  settingsUpdate,
  fieldUpdate,
  fieldValueUpdate,
  optionsUpdate,
  contentChange,
  inactiveStatusChange
} from '../../../../../../window-message-exchange/actions';
import { getMessageBroker, removeMessageBroker } from './broker';
import * as actions from '../store/email-template.actions';
import * as formActions from '../stepGeneralInformation/store/step-general-information.actions';
import { openHtmlVersionEditorPopup } from '../stepEmailCreative/store/step-email-creative.actions';
import { SaveOrigin } from '../email-template.model';

@Injectable()
export class EmailFlowsPowerAppMessageService {
  private channel!: MessageChannel;
  private channelOpen = new BehaviorSubject(false);
  public readonly channelOpen$ = this.channelOpen.asObservable();
  private readonly broker = getMessageBroker();

  constructor(private store: Store<IAppState>) {}

  public readonly init = (windowParent: Window): void => {
    this.channel = this.broker.addChannel('PowerApp', windowParent);
    this.channel
      .on(
        open(() => {
          this.store.dispatch(actions.requestSettings());
          this.channelOpen.next(true);
        })
      )
      .on(close(() => this.channelOpen.next(false)))
      .on(deny(console.error))
      .on(invalid(console.error))
      .onMessage(filter<InactiveStatusChangeAction>(inactiveStatusChange.type, ({ inactive }) => this.store.dispatch(actions.toggleInactiveStatus(inactive))))
      .onMessage(filter<FieldUpdateAction>(fieldUpdate.type, (fields) => this.store.dispatch(formActions.setFields(fields))))
      .onMessage(filter<OptionsUpdateAction>(optionsUpdate.type, (options) => this.store.dispatch(formActions.setOptions(options))))
      .onMessage(filter<SettingsUpdateAction>(settingsUpdate.type, (settings) => this.store.dispatch(actions.setSettings(settings))))
      .onMessage(
        filter<EntitySearchResultsAction>(entitySearchResults.type, (result) => {
          let action: Function;
          switch (result.entityName) {
            case WebAppEntityName.Campaign:
              action = formActions.getCampaignsSuccess;
              break;
            case WebAppEntityName.SystemUser:
              if (result.meta === FieldName.FromUserId) action = formActions.getFromUsersSuccess;
              if (result.meta === FieldName.OwnerId) action = formActions.getOwnersSuccess;
              break;
            case WebAppEntityName.EmailCName:
              action = formActions.getEmailCNamesSuccess;
              break;
            case WebAppEntityName.SubscriptionList:
              action = formActions.getSubscriptionListsSuccess;
              break;
          }
          action && this.store.dispatch(action(result));
        })
      )
      .onMessage(filter<FieldVisibilityUpdateAction>(fieldVisibilityUpdate.type, (visibility) => this.store.dispatch(formActions.setFieldVisibility(visibility))))
      .onMessage(filter<SaveAction>(save.type, () => this.store.dispatch(actions.attemptSave(SaveOrigin.CRMRibbon))))
      .onMessage(filter<SaveSuccessAction>(saveSuccess.type, () => this.store.dispatch(formActions.saveFormChangesSuccess())))
      .onMessage(filter<SaveFailAction>(saveFail.type, () => this.store.dispatch(formActions.saveFormChangesFail())))
      .onMessage(filter<OpenEditorAction>(openEditor.type, () => this.store.dispatch(openHtmlVersionEditorPopup())))
      .connect();
  };

  public readonly openForm = (name: WebAppEntityName, id: string): void => {
    this.channel && this.channel.sendMessage(openForm({ name, id }));
  };

  public readonly openNewForm = (name: WebAppEntityName): void => {
    this.channel && this.channel.sendMessage(openForm({ name }));
  };

  public readonly requestSettings = (): void => {
    this.channel && this.channel.sendMessage(settingsRequest());
  };

  public readonly sendSave = (): void => {
    this.channel && this.channel.sendMessage(save());
  };

  public readonly lockSave = (locked = true): void => {
    this.channel && this.channel.sendMessage(lockSave(locked));
  };

  public readonly sendFieldValue = (fieldName: FieldName, value: TFieldValue): void => {
    this.channel && this.channel.sendMessage(fieldValueUpdate({ [fieldName]: value }));
  };

  public readonly searchEntities = (search: IEntitySearch): void => {
    this.channel && this.channel.sendMessage(entitySearch(search));
  };

  public readonly notifyContentChanged = () => this.channel && this.channel.sendMessage(contentChange());

  public readonly destroy = (): void => removeMessageBroker();
}

import { broker, mergeContracts, MessageBroker } from 'window-messages';
import { contract as powerAppContract } from '../../../../../../window-message-exchange/contracts/webapp';
import { contract as embeddedEditorContract } from '../../../../app/models/embedded-systems/editors/messages/contract';

export const getMessageBroker = (): MessageBroker => {
  if ((globalThis as any).wmBroker) return (globalThis as any).wmBroker;
  broker('WebApp', mergeContracts(powerAppContract, embeddedEditorContract), {}, true);
  return (globalThis as any).wmBroker;
};

export const removeMessageBroker = (): void => {
  if ((globalThis as any).wmBroker) delete (globalThis as any).wmBroker;
};

import { actionSchema } from 'window-messages';
import { action } from 'ts-action';

const type = 'Inactive Status Change Notification';

export const InactiveStatusChange = actionSchema(type);

export interface InactiveStatusChangeAction {
  inactive: boolean;
}

export const inactiveStatusChange = action(type, (inactive: boolean): InactiveStatusChangeAction => ({ inactive }));

export enum CRMEntityName {
  Campaign = 'campaign',
  EmailCName = 'cdi_emailcname',
  EmailSend = 'cdi_emailsend',
  EmailTemplate = 'cdi_emailtemplate',
  SubscriptionList = 'cdi_subscriptionlist',
  SystemUser = 'systemuser'
}

export enum WebAppEntityName {
  Campaign = 'campaign',
  EmailCName = 'emailCName',
  SubscriptionList = 'subscriptionList',
  SystemUser = 'systemuser'
}

export const WebAppEntityNameMap = {
  [CRMEntityName.Campaign]: WebAppEntityName.Campaign,
  [CRMEntityName.EmailCName]: WebAppEntityName.EmailCName,
  [CRMEntityName.SubscriptionList]: WebAppEntityName.SubscriptionList,
  [CRMEntityName.SystemUser]: WebAppEntityName.SystemUser
};

export const CrmEntityNameMap = {
  [WebAppEntityName.Campaign]: CRMEntityName.Campaign,
  [WebAppEntityName.EmailCName]: CRMEntityName.EmailCName,
  [WebAppEntityName.SubscriptionList]: CRMEntityName.SubscriptionList,
  [WebAppEntityName.SystemUser]: CRMEntityName.SystemUser
};

export enum FieldName {
  CampaignId = 'cdi_campaignid',
  Category = 'cdi_category',
  CreatedBy = 'createdby',
  CreatedOn = 'createdon',
  EditorType = 'cdi_editortype',
  EmailCNameId = 'cdi_emailcnameid',
  EmailTemplateId = 'cdi_emailtemplateid',
  FromEmail = 'cdi_fromemail',
  FromName = 'cdi_fromname',
  FromRecordOwner = 'cdi_fromrecordowner',
  FromUserId = 'cdi_fromuserid',
  Name = 'cdi_name',
  OwnerId = 'ownerid',
  PreHeader = 'cdi_preheader',
  QuickSendTemplate = 'cdi_quicksendtemplate',
  Subject = 'cdi_subject',
  SubscriptionListId = 'cdi_subscriptionlistid',
  Tags = 'cdi_tags',
  Transactional = 'cdi_transactional'
  /**
   * Additional fields present in email template view
   * but not directly used by enhanced form:
   *
   * ClassicFormDndEditorFormField = 'cdi_htmlversionlastmodified',
   * CreatedOnBehalfBy = 'createdonbehalfby',
   * EmailFlowViews = 'cdi_emailflowviews',
   * ImportSequenceNumber = 'importsequencenumber',
   * IsAClone = 'cdi_isaclone',
   * ModifiedBy = 'modifiedby',
   * ModifiedOn = 'modifiedon',
   **/
}

// @ts-ignore
export const fieldNames = Object.values(FieldName);

export type RequiredLevel = 'none' | 'required' | 'recommended';

export interface IOptionItem {
  text: string;
  value: number;
}

export interface IOpenFormRequest {
  name: WebAppEntityName;
  id?: string;
}

import { createAction } from '@ngrx/store';
import { createAsyncProcessActions } from '../../../../../src/app/models/async-process';
import { EditorUrlTemplateMap } from '../../../../../../shared-contracts/editors.model';
import { CRMSettings } from '../../../../../../shared-contracts/settings.model';
import { SaveOrigin } from '../email-template.model';

const view = 'Email Template';

export const setSettings = createAction(`[${view}] Set settings`, (settings: CRMSettings) => ({ settings }));
export const setEntityId = createAction(`[${view}] Set entityId`, (entityId: string) => ({ entityId }));
export const requestSettings = createAction(`[${view}] Request settings`);
export const toggleInactiveStatus = createAction(`[${view}] Toggle inactive status`, (inactive: boolean) => ({ inactive }));
export const showEmbeddedHtmlVersionEditorView = createAction(`[${view}] Show embedded html editor view`);
export const showFormView = createAction(`[${view}] Show form view`);
export const setEditorUrls = createAction(`[${view}] Set editor urls`, (editorBaseUrl: EditorUrlTemplateMap) => ({ editorBaseUrl }));
export const setSelectedEditorUrl = createAction(`[${view}] Set selected editor url`, (selectedHtmlEditorUrl = '', selectedTextEditorUrl = '') => ({
  selectedHtmlEditorUrl,
  selectedTextEditorUrl
}));
export const attemptSave = createAction(`[${view}] Save`, (saveOrigin: SaveOrigin = SaveOrigin.WebAppFooter) => ({ saveOrigin }));
export const attemptSaveNewRecord = createAction(`[${view}] Save new template`, (saveOrigin: SaveOrigin = SaveOrigin.WebAppFooter) => ({ saveOrigin }));
export const attemptSaveExistingRecord = createAction(`[${view}] Save existing template`, (saveOrigin: SaveOrigin = SaveOrigin.WebAppFooter) => ({ saveOrigin }));
export const [save, saveSuccess, saveFail] = createAsyncProcessActions(view, 'Save all');
export const saveSkip = createAction(`[${view}] Save skipped`);
export const highlightIncompleteFields = createAction(`[${view}] Toogle incomplete fields highlight`, (toggle: boolean = true) => ({ toggle }));
export const displaySuccessMessage = createAction(`[${view}] Display success message`, (message: string, title?: string) => ({ message, title }));
export const displayErrorMessage = createAction(`[${view}] Display error message`, (message: string, title?: string) => ({ message, title }));
export const displayInfoMessage = createAction(`[${view}] Display info message`, (message: string, title?: string) => ({ message, title }));
export const artificiallyFlagFormAsDirty = createAction(`[${view}] Artificially flag form as dirty`);

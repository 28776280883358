import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'email-template', loadChildren: () => import('./views/email-template/email-template.module').then((m) => m.EmailTemplateModule) },
  {
    path: '**',
    loadChildren: () => import('./views/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { action } from 'ts-action';
import { actionSchema } from 'window-messages';
import { WebAppEntityName } from '../../shared-contracts/dynamics.model';
import { IEntitySearchResults } from '../../shared-contracts/lookups.model';

const type = 'Entity Search Results';

export const EntitySearchResults = actionSchema(type, {
  properties: {
    // @ts-ignore
    entityName: { type: Object.values(WebAppEntityName) },
    entities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          name: { type: 'string' },
          additionalData: { type: 'array', items: { type: 'string' } }
        },
        required: ['id', 'name']
      }
    },
    hasMore: { type: 'boolean' },
    request: { type: 'object', additionalProperties: true },
    meta: { type: 'string' }
  },
  required: ['entityName', 'entities', 'hasMore', 'request']
});

export interface EntitySearchResultsAction extends IEntitySearchResults {}

export const entitySearchResults = action(type, (results: IEntitySearchResults): EntitySearchResultsAction => ({ ...results }));

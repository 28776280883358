import { createAction } from '@ngrx/store';

/**
 * @param view Name of area of concern or scope. It could be a feature or the name of the app itself, in title case.
 * @param actionDescription A short description of event or action in Sentence case.
 * @returns {Array} Returns a list of actions in the following order: start action, success action, and failed action,
 */
export const createAsyncProcessActions = (view: string, actionDescription: string) => [
  createAction(`[${view}] ${actionDescription}`),
  createAction(`[${view}] ${actionDescription} successful`),
  createAction(`[${view}] ${actionDescription} failed`)
];

import { actionSchema } from 'window-messages';
import { action } from 'ts-action';
import { IEmailTemplateFormOptions } from '../../shared-contracts/form.model';

const type = 'Options Update';

const OptionItemSchema = {
  type: 'object',
  properties: {
    text: { type: 'string' },
    value: { type: ['number', 'null'] }
  },
  required: ['text', 'value']
};

export const OptionsUpdate = actionSchema(type, {
  properties: {
    categories: { type: 'array', items: OptionItemSchema },
    editorTypes: { type: 'array', items: OptionItemSchema }
  },
  required: ['categories', 'editorTypes']
});

export interface OptionsUpdateAction extends IEmailTemplateFormOptions {}

export const optionsUpdate = action(type, (options: IEmailTemplateFormOptions): OptionsUpdateAction => ({ ...options }));

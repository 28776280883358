import { createAction } from '@ngrx/store';
import { TField, TFieldValue } from '../step-general-information.model';
import { Fields, IEmailTemplateFormOptions, IVisibility } from '../../../../../../../shared-contracts/form.model';
import { WebAppEntityName } from '../../../../../../../shared-contracts/dynamics.model';
import { IEntitySearch, IEntitySearchResults, IEntityMatch } from '../../../../../../../shared-contracts/lookups.model';
import { createAsyncProcessActions } from '../../../../../../src/app/models/async-process';

const view = 'Email Template';

export const openForm = createAction(`[${view}] Open form`, (entity: WebAppEntityName, id: IEntityMatch['id']) => ({ entity, id }));
export const openNewForm = createAction(`[${view}] Open new form`, (entity: WebAppEntityName) => ({ entity }));
export const openOwner = createAction(`[${view}] Open owner`, (value: IEntityMatch) => ({ value }));
export const setFromRecordOwner = createAction(`[${view}] Set field fromRecordOwner`, (value: boolean) => ({ value }));

export const setFieldVisibility = createAction(`[${view}] Set field visibility`, (visibility: IVisibility) => ({ visibility }));
export const setFields = createAction(`[${view}] Set field values`, (fields: Fields) => ({ ...fields }));
export const setOptions = createAction(`[${view}] Set form options`, (options: IEmailTemplateFormOptions) => ({ ...options }));
export const setFieldValue = createAction(`[${view}] Set field value`, (field: TField, value: TFieldValue) => ({ field, value }));

export const setShowQuickSend = createAction(`[${view}] set show quick send`);
export const toggleShowQuickSend = createAction(`[${view}] toggle show quick send`);

export const getCampaigns = createAction(`[${view}] get campaigns`, (search: IEntitySearch) => search);
export const getCampaignsSuccess = createAction(`[${view}] get campaigns success`, (result: IEntitySearchResults) => result);
export const getCampaignsFail = createAction(`[${view}] get campaigns failed`);

export const getEmailCNames = createAction(`[${view}] get cnames`, (search: IEntitySearch) => search);
export const getEmailCNamesSuccess = createAction(`[${view}] get cnames success`, (result: IEntitySearchResults) => result);
export const getEmailCNamesFail = createAction(`[${view}] get cnames failed`);

export const getFromUsers = createAction(`[${view}] get users`, (search: IEntitySearch) => search);
export const getFromUsersSuccess = createAction(`[${view}] get users success`, (result: IEntitySearchResults) => result);
export const getFromUsersFail = createAction(`[${view}] get users failed`);

export const getOwners = createAction(`[${view}] get owners`, (search: IEntitySearch) => search);
export const getOwnersSuccess = createAction(`[${view}] get owners success`, (result: IEntitySearchResults) => result);
export const getOwnersFail = createAction(`[${view}] get owners failed`);

export const getSubscriptionLists = createAction(`[${view}] get subscription lists`, (search: IEntitySearch) => search);
export const getSubscriptionListsSuccess = createAction(`[${view}] get subscription lists success`, (result: IEntitySearchResults) => result);
export const getSubscriptionListsFail = createAction(`[${view}] get subscription lists failed`);

export const requiredFieldsCheckFail = createAction(`[${view}] required fields blank`);
export const [saveFormChanges, saveFormChangesSuccess, saveFormChangesFail] = createAsyncProcessActions(view, 'Save crm fields');

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSpinnerComponent } from './inline-spinner/inline-spinner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OverlaySpinnerComponent } from './overlay-spinner/overlay-spinnercomponent';

@NgModule({
  declarations: [InlineSpinnerComponent, OverlaySpinnerComponent, NotFoundComponent],
  imports: [CommonModule],
  exports: [InlineSpinnerComponent, OverlaySpinnerComponent, NotFoundComponent]
})
export class SharedComponentsModule {}

import { IEnvironment } from '../app/app.model';

export const environment: IEnvironment = {
  production: true,
  blobFilesBaseUrl: (window as any).blobFilesBaseUrl,
  blockEditorUrl: (window as any).blockEditorUrl,
  commonWebUrl: (window as any).commonWebUrl,
  dndEditorUrl: (window as any).dndEditorUrl,
  editorLoaderUrl: (window as any).editorLoaderUrl,
  freestyleEditorUrl: (window as any).freestyleEditorUrl,
  legacyDndEditorUrl: (window as any).legacyDndEditorUrl,
  emailDesignerUrl: (window as any).emailDesignerUrl,
  msCrmAppUrl: (window as any).msCrmAppUrl,
  templatePreviewUrl: (window as any).templatePreviewUrl,
  textVersionEditorUrl: (window as any).textVersionEditorUrl
};

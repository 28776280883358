import { IAsyncProcessStatus } from '../../../../../src/app/models/async-process';

export enum TextVersionView {
  Loading = 'loading',
  Error = 'error',
  Blank = 'blank',
  Content = 'content'
}

export interface ITextVersionState {
  data: {
    exists: boolean;
    withUnsavedChanges: boolean;
    empty: boolean;
    generateTextScheduled: boolean;
  };
  process: {
    fetchText: IAsyncProcessStatus;
    loadTextEditor: IAsyncProcessStatus;
    saveTextChanges: IAsyncProcessStatus;
    generateText: IAsyncProcessStatus;
    personalizeText: IAsyncProcessStatus;
  };
}

export const initialState: ITextVersionState = {
  data: {
    exists: null,
    withUnsavedChanges: false,
    empty: null,
    generateTextScheduled: false
  },
  process: {
    fetchText: {
      inProgress: false,
      success: false,
      fail: false
    },
    loadTextEditor: {
      inProgress: false,
      success: false,
      fail: false
    },
    saveTextChanges: {
      inProgress: false,
      success: false,
      fail: false
    },
    generateText: {
      inProgress: false,
      success: false,
      fail: false
    },
    personalizeText: {
      inProgress: false,
      success: false,
      fail: false
    }
  }
};

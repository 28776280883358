import { actionSchema } from 'window-messages';
import { action } from 'ts-action';
import { CRMSettings } from '../../shared-contracts/settings.model';
import { CRMEntityName } from '../../shared-contracts/dynamics.model';

const type = 'Settings Update';

export const SettingsUpdate = actionSchema(type, {
  properties: {
    region: { type: 'string' },
    fixedRegion: { type: 'string' },
    accountKey: { type: 'string' },
    crmType: { type: 'string' },
    crmVersion: { type: 'string' },
    orgLcid: { type: 'number' },
    userLcid: { type: 'number' },
    subscriptionManagementStatus: { type: 'string' },
    entityId: { type: 'string' },
    entityName: { const: CRMEntityName.EmailTemplate },
    userId: { type: 'string' },
    appId: { type: 'string' },
    sessionId: { type: 'string' },
    crmSpecificVersion: { type: 'string' },
    orgname: { type: 'string' }
  }
});

export interface SettingsUpdateAction extends CRMSettings {}

export const settingsUpdate = action(type, (settings: CRMSettings): SettingsUpdateAction => ({ ...settings }));

import { actionSchema } from 'window-messages';
import { action } from 'ts-action';
import { IOpenFormRequest, WebAppEntityName } from '../../shared-contracts/dynamics.model';

const type = 'Form Open';

export const OpenForm = actionSchema(type, {
  properties: {
    // @ts-ignore
    name: { enum: Object.values(WebAppEntityName) },
    id: { type: 'string' }
  },
  required: ['name']
});

export interface OpenFormAction extends IOpenFormRequest {}

export const openForm = action(type, (form: IOpenFormRequest): OpenFormAction => ({ ...form }));

import { actionSchema } from 'window-messages';
import { FieldValues } from '../../shared-contracts/form.model';
import { action } from 'ts-action';
import { FieldName } from '../../shared-contracts/dynamics.model';

const type = 'Field Value Update';

export const FieldValueUpdate = actionSchema(type, {
  properties: {
    [FieldName.CampaignId]: { type: ['object', 'null'] },
    [FieldName.Category]: { type: 'number' },
    [FieldName.EditorType]: { type: 'number' },
    [FieldName.EmailCNameId]: { type: ['object', 'null'] },
    [FieldName.EmailTemplateId]: { type: 'string' },
    [FieldName.FromEmail]: { type: 'string' },
    [FieldName.FromName]: { type: 'string' },
    [FieldName.FromRecordOwner]: { type: 'boolean' },
    [FieldName.FromUserId]: { type: ['object', 'null'] },
    [FieldName.Name]: { type: 'string' },
    [FieldName.OwnerId]: { type: ['object', 'null'] },
    [FieldName.PreHeader]: { type: 'string' },
    [FieldName.QuickSendTemplate]: { type: 'boolean' },
    [FieldName.Subject]: { type: 'string' },
    [FieldName.SubscriptionListId]: { type: ['object', 'null'] },
    [FieldName.Tags]: { type: 'string' },
    [FieldName.Transactional]: { type: 'boolean' }
  },
  additionalProperties: true
});

export interface FieldValueUpdateAction extends FieldValues {}

export const fieldValueUpdate = action(type, (fields: FieldValues): FieldValueUpdateAction => ({ ...fields }));

export enum EditorValue {
  BlockEditor = 1,
  FreeStyle = 2,
  Html = 3,
  LegacyDragAndDrop = 4,
  DragAndDrop = 5,
  EmailDesigner = 6
}

export interface EditorUrlTemplateMap {
  [EditorValue.BlockEditor]: string;
  [EditorValue.FreeStyle]: string;
  [EditorValue.Html]: string;
  [EditorValue.LegacyDragAndDrop]: string;
  [EditorValue.DragAndDrop]: string;
  [EditorValue.EmailDesigner]: string;
  textVersion: string;
  preview: string;
}

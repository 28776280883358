import { actionSchema } from 'window-messages';
import { action } from 'ts-action';

export interface SaveAction {}
export interface SaveSuccessAction {}
export interface SaveFailAction {}

export const Save = actionSchema('Form save');
export const save = action('Form save', (): SaveAction => ({}));

export const SaveSuccess = actionSchema('Form save successful');
export const saveSuccess = action('Form save successful', (): SaveSuccessAction => ({}));

export const SaveFail = actionSchema('Form save failed');
export const saveFail = action('Form save failed', (): SaveFailAction => ({}));

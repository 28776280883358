import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailFlowsPowerAppMessageService } from './views/email-template/messaging/powerapp-message.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedComponentsModule } from './components/shared-components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-left',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 0,
      newestOnTop: true,
      tapToDismiss: true,
      timeOut: 4000,
      extendedTimeOut: 1000,
      disableTimeOut: false,
      easeTime: 300
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      name: 'Email Flows WebApp',
      features: {
        pause: false,
        lock: false,
        persist: false,
        export: false,
        import: false,
        jump: true,
        skip: true,
        reorder: false,
        dispatch: true,
        test: false
      }
    }),
    EffectsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedComponentsModule
  ],
  providers: [NgbActiveModal, EmailFlowsPowerAppMessageService],
  bootstrap: [AppComponent]
})
export class AppModule {}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { FieldName } from '../../../../../../shared-contracts/dynamics.model';
import { EditorValue } from '../../../../../../shared-contracts/editors.model';
import { IEntitySearchResults } from '../../../../../../shared-contracts/lookups.model';
import { IField, Fields, IVisibility } from '../../../../../../shared-contracts/form.model';
import { IAsyncProcessStatus } from '../../../../../src/app/models/async-process/model';

type TFields = IFormState['data']['fields'];

export type TField = Exclude<keyof TFields, 'emailTemplateId' | 'fromRecordOwner'>;

export type TFieldValue = TFields[TField]['value'];

export type IWebAppToCrmFieldMap = {
  [key in TField]: FieldName;
};

export const lookupFieldNames = [FieldName.EmailCNameId, FieldName.OwnerId, FieldName.FromUserId, FieldName.CampaignId, FieldName.SubscriptionListId];

export interface IFields {
  campaign: Fields[FieldName.CampaignId];
  category: Fields[FieldName.Category];
  editorType: Fields[FieldName.EditorType];
  emailTemplateId: Fields[FieldName.EmailTemplateId];
  emailCName: Fields[FieldName.EmailCNameId];
  fromEmail: Fields[FieldName.FromEmail];
  fromName: Fields[FieldName.FromName];
  fromRecordOwner: Fields[FieldName.FromRecordOwner];
  fromUser: Fields[FieldName.FromUserId];
  name: Fields[FieldName.Name];
  owner: Fields[FieldName.OwnerId];
  preheader: Fields[FieldName.PreHeader];
  quickSendTemplate: Fields[FieldName.QuickSendTemplate];
  subject: Fields[FieldName.Subject];
  tags: Fields[FieldName.Tags];
  transactional: Fields[FieldName.Transactional];
  subscriptionList: Fields[FieldName.SubscriptionListId];
}

export type TFieldList = IFields[keyof IFields][];

export interface IFieldVisibility {
  campaign: IVisibility[FieldName.CampaignId];
  category: IVisibility[FieldName.Category];
  editorType: IVisibility[FieldName.EditorType];
  emailCName: IVisibility[FieldName.EmailCNameId];
  fromEmail: IVisibility[FieldName.FromEmail];
  fromName: IVisibility[FieldName.FromName];
  fromRecordOwner: IVisibility[FieldName.FromRecordOwner];
  fromUser: IVisibility[FieldName.FromUserId];
  name: IVisibility[FieldName.Name];
  owner: IVisibility[FieldName.OwnerId];
  preheader: IVisibility[FieldName.PreHeader];
  quickSendTemplate: IVisibility[FieldName.QuickSendTemplate];
  subject: IVisibility[FieldName.Subject];
  tags: IVisibility[FieldName.Tags];
  transactional: IVisibility[FieldName.Transactional];
  subscriptionList: IVisibility[FieldName.SubscriptionListId];
}

export const webAppToCrmFieldMap: Omit<IWebAppToCrmFieldMap, 'emailTemplateId' | 'fromRecordOwner'> = {
  campaign: FieldName.CampaignId,
  category: FieldName.Category,
  editorType: FieldName.EditorType,
  emailCName: FieldName.EmailCNameId,
  fromEmail: FieldName.FromEmail,
  fromName: FieldName.FromName,
  fromUser: FieldName.FromUserId,
  name: FieldName.Name,
  owner: FieldName.OwnerId,
  preheader: FieldName.PreHeader,
  quickSendTemplate: FieldName.QuickSendTemplate,
  subject: FieldName.Subject,
  subscriptionList: FieldName.SubscriptionListId,
  tags: FieldName.Tags,
  transactional: FieldName.Transactional
};

export interface ICategory {
  id: string;
  label: string;
}

export interface IUser {
  name: string;
  email: string;
}

export interface IEmailTemplateFormMeta {
  author: IUser | null;
  createdDate: IField<Date>;
  lastModified: number | null;
  lastVisited: number | null;
}

export interface IFormState {
  data: {
    withUnsavedChanges: boolean;
    campaigns?: IEntitySearchResults;
    emailCNames?: IEntitySearchResults;
    fromUsers?: IEntitySearchResults;
    subscriptionLists?: IEntitySearchResults;
    owners?: IEntitySearchResults;
    fields: IFields;
    fieldsVisibility: IFieldVisibility;
    meta: IEmailTemplateFormMeta;
    quickSendExpanded: boolean;
    initialEditorType: EditorValue;
    editorTypeOptions: number[];
    categoryOptions: ICategory[];
    switchEditorTypeDisplayed: boolean;
  };
  process: {
    campaignLookup: IAsyncProcessStatus;
    emailCNameLookup: IAsyncProcessStatus;
    fromUsersLookup: IAsyncProcessStatus;
    subscriptionListLookup: IAsyncProcessStatus;
    ownerLookup: IAsyncProcessStatus;
    saveFormChanges: IAsyncProcessStatus;
  };
}

const fieldDefaults = <T extends IFields[keyof IFields]>(name: FieldName, value: T['value']): T =>
  ({
    name,
    value,
    required: false,
    disabled: true,
    maxLength: null
  } as T);

export const initialState: IFormState = {
  data: {
    withUnsavedChanges: false,
    campaigns: null,
    emailCNames: null,
    fromUsers: null,
    subscriptionLists: null,
    owners: null,
    fields: {
      name: fieldDefaults(FieldName.Name, ''),
      subject: fieldDefaults(FieldName.Subject, ''),
      preheader: fieldDefaults(FieldName.PreHeader, ''),
      category: fieldDefaults(FieldName.Category, null),
      emailCName: fieldDefaults(FieldName.EmailCNameId, null),
      owner: fieldDefaults(FieldName.OwnerId, null),
      tags: fieldDefaults(FieldName.Tags, ''),
      editorType: fieldDefaults(FieldName.EditorType, null),
      emailTemplateId: fieldDefaults(FieldName.EmailTemplateId, ''),
      fromRecordOwner: fieldDefaults(FieldName.FromRecordOwner, null),
      quickSendTemplate: fieldDefaults(FieldName.QuickSendTemplate, false),
      fromName: fieldDefaults(FieldName.FromName, ''),
      campaign: fieldDefaults(FieldName.CampaignId, null),
      fromUser: fieldDefaults(FieldName.FromUserId, null),
      fromEmail: fieldDefaults(FieldName.FromEmail, ''),
      transactional: fieldDefaults(FieldName.Transactional, false),
      subscriptionList: fieldDefaults(FieldName.SubscriptionListId, null)
    },
    fieldsVisibility: {
      name: true,
      subject: true,
      preheader: true,
      category: true,
      emailCName: true,
      owner: true,
      tags: true,
      editorType: true,
      fromRecordOwner: true,
      quickSendTemplate: true,
      fromName: true,
      campaign: true,
      fromUser: true,
      fromEmail: true,
      transactional: true,
      subscriptionList: true
    },
    meta: {
      author: null,
      createdDate: null,
      lastModified: null,
      lastVisited: null
    },
    quickSendExpanded: false,
    initialEditorType: null,
    editorTypeOptions: [],
    categoryOptions: [],
    switchEditorTypeDisplayed: false
  },
  process: {
    campaignLookup: {
      inProgress: false,
      success: false,
      fail: false
    },
    emailCNameLookup: {
      inProgress: false,
      success: false,
      fail: false
    },
    fromUsersLookup: {
      inProgress: false,
      success: false,
      fail: false
    },
    subscriptionListLookup: {
      inProgress: false,
      success: false,
      fail: false
    },
    ownerLookup: {
      inProgress: false,
      success: false,
      fail: false
    },
    saveFormChanges: {
      inProgress: false,
      success: false,
      fail: false
    }
  }
};

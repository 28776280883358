import { IChannelContract, actionSchema } from 'window-messages';
import { InboundMessage, Message, OutboundMessage } from './types';
import { Action } from 'ts-action';

export interface ContentLoadAction extends Action {}

export interface ContentChangeAction extends Action {
  empty: boolean;
}

export interface DimensionChangeAction extends Action {
  height: number;
}

export interface SaveSuccessAction extends Action {}

export interface SaveFailAction extends Action {}

export interface ProcessStartAction extends Action {
  process: string;
}

export interface ProcessSuccessAction extends Action {
  process: string;
}

export interface ProcessFailAction extends Action {
  process: string;
}

export const contract: IChannelContract = {
  accepted: [
    actionSchema(InboundMessage.ContentLoaded),
    actionSchema(InboundMessage.ContentChanged, {
      properties: { empty: { type: 'boolean' } },
      required: ['empty']
    }),
    actionSchema(InboundMessage.DimensionChanged, {
      properties: { height: { type: 'number' } },
      required: ['height']
    }),
    actionSchema(InboundMessage.SaveSuccess),
    actionSchema(InboundMessage.SaveFail),
    actionSchema(Message.ProcessStart, {
      properties: { process: { type: 'string' } },
      required: ['process']
    }),
    actionSchema(Message.ProcessSuccess, {
      properties: { process: { type: 'string' } },
      required: ['process']
    }),
    actionSchema(Message.ProcessFail, {
      properties: { process: { type: 'string' } },
      required: ['process']
    })
  ],
  emitted: [
    actionSchema(OutboundMessage.ContentStatusRequest),
    actionSchema(OutboundMessage.Save),
    actionSchema(Message.ProcessStart, {
      properties: { process: { type: 'string' } },
      required: ['process']
    }),
    actionSchema(Message.ProcessSuccess, {
      properties: { process: { type: 'string' } },
      required: ['process']
    }),
    actionSchema(Message.ProcessFail, {
      properties: { process: { type: 'string' } },
      required: ['process']
    })
  ]
};

import { actionSchema } from 'window-messages';
import { action } from 'ts-action';

const type = 'Editor Open';

export const OpenEditor = actionSchema(type);

export interface OpenEditorAction {}

export const openEditor = action(type);

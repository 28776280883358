import { actionSchema } from 'window-messages';
import { Fields } from '../../shared-contracts/form.model';
import { action } from 'ts-action';
import { FieldName } from '../../shared-contracts/dynamics.model';
import { OptionsUpdate } from './options';

const type = 'Field Update';

export const FieldSchema = {
  type: 'object',
  properties: {
    name: { type: 'string' },
    value: {},
    maxLength: { type: ['number', 'null'] },
    required: { type: 'boolean' },
    disabled: { type: 'boolean' }
  },
  required: ['name', 'maxLength', 'required', 'disabled'],
  additionalProperties: false
};

export const FieldUpdate = actionSchema(type, {
  properties: {
    [FieldName.CampaignId]: FieldSchema,
    [FieldName.Category]: FieldSchema,
    [FieldName.CreatedBy]: FieldSchema,
    [FieldName.CreatedOn]: FieldSchema,
    [FieldName.EditorType]: FieldSchema,
    [FieldName.EmailCNameId]: FieldSchema,
    [FieldName.EmailTemplateId]: FieldSchema,
    [FieldName.FromEmail]: FieldSchema,
    [FieldName.FromName]: FieldSchema,
    [FieldName.FromRecordOwner]: FieldSchema,
    [FieldName.FromUserId]: FieldSchema,
    [FieldName.Name]: FieldSchema,
    [FieldName.OwnerId]: FieldSchema,
    [FieldName.PreHeader]: FieldSchema,
    [FieldName.QuickSendTemplate]: FieldSchema,
    [FieldName.Subject]: FieldSchema,
    [FieldName.SubscriptionListId]: FieldSchema,
    [FieldName.Tags]: FieldSchema,
    [FieldName.Transactional]: FieldSchema,
    options: { type: 'object', ...OptionsUpdate.data }
  },
  additionalProperties: true
});

export interface FieldUpdateAction extends Fields {}

export const fieldUpdate = action(type, (fields: Fields): FieldUpdateAction => ({ ...fields }));

import { createAction } from '@ngrx/store';
import { EditorValue } from '../../../../../../../shared-contracts/editors.model';
import { createAsyncProcessActions } from '../../../../../../src/app/models/async-process';

const view = 'Email Template';

export const setSwitchEditorTypeDisplayed = createAction(`[${view}] Set 'Switch Editor Dialog' Displayed`, (value: boolean) => ({ value }));
export const switchEditorType = createAction(`[${view}] Switch editor type`, (editorType: EditorValue) => ({ editorType }));
export const fetchHtml = createAction(`[${view}] Fetch html`, (templateId: string, accountKey: string, region: string) => ({ templateId, accountKey, region }));
export const fetchHtmlSuccess = createAction(`[${view}] Fetch html successful`, (exists: boolean) => ({ exists }));
export const fetchHtmlFail = createAction(`[${view}] Fetch html failed`);
export const displayHtmlVersionEditor = createAction(`[${view}] Display html editor`);
export const htmlChanged = createAction(`[${view}] Html changed`, (empty: boolean = false) => ({ empty }));
export const [loadHtmlEditor, loadHtmlEditorSuccess, loadHtmlEditorFail] = createAsyncProcessActions(view, 'Load html editor');
export const [saveHtmlChanges, saveHtmlChangesSuccess, saveHtmlChangesFail] = createAsyncProcessActions(view, 'Save html changes');
export const [openHtmlVersionEditorPopup, openHtmlVersionEditorPopupSuccess, openHtmlVersionEditorPopupFail] = createAsyncProcessActions(
  view,
  'Open HTML content pop window'
);
export const htmlVersionEditorPopupWindowClosed = createAction(`[${view}] HTML content popup window closed`);
export const [fetchThumbnail, fetchThumbnailSuccess, fetchThumbnailFail] = createAsyncProcessActions(view, 'Fetch thumbnail');
export const refreshThumbnail = createAction(`[${view}] Refresh thumbnail`);
export const dialogOpened = createAction(`[${view}] HTML content internal dialog opened`);

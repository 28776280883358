import { actionSchema } from 'window-messages';
import { action } from 'ts-action';

const type = 'Settings Request';

export const SettingsRequest = actionSchema(type);

export interface SettingsRequestAction {}

export const settingsRequest = action(type, (): SettingsRequestAction => ({}));

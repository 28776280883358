import { actionSchema } from 'window-messages';
import { action } from 'ts-action';

const type = 'Content Change Notification';

export const ContentChange = actionSchema(type);

export interface ContentChangeAction {}

export const contentChange = action(type, (): ContentChangeAction => ({}));

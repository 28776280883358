import { actionSchema } from 'window-messages';
import { action } from 'ts-action';
import { IEntitySearch } from '../../shared-contracts/lookups.model';
import { WebAppEntityName } from '../../shared-contracts/dynamics.model';

const type = 'Entity Search';

export const EntitySearch = actionSchema(type, {
  properties: {
    // @ts-ignore
    name: { type: Object.values(WebAppEntityName) },
    field: { type: 'string' },
    search: { type: 'string' },
    pageSize: { type: 'number', minimum: 20 },
    pageNo: { type: 'number', minimum: 1 }
  },
  required: ['name', 'search', 'pageSize', 'pageNo']
});

export interface EntitySearchAction extends IEntitySearch {}

export const entitySearch = action(
  type,
  (search: IEntitySearch): EntitySearchAction => ({
    ...search,
    pageNo: search.pageNo || 1,
    pageSize: search.pageSize || 20
  })
);
